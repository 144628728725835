


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class Agreement extends Vue {
    @Prop(String) placeholder!: string
    @Prop([String, Number]) value!: [string, number]
    @Prop(String) valueName!: string
    @Prop(String) type!: string
    @Prop({ default: '' }) typeName?: string
    @Prop({ default: false }) canTypeChange?: boolean

    localValue = this.value
    focus = false

    @Watch('value')
    onValueChange() {
        this.localValue = this.value
    }

    inputTypeChange() {
        let _type = ''
        if (this.type === 'password') {
            _type = 'text'
        } else if (this.type === 'text') {
            _type = 'password'
        }
        this.$emit('inputTypeChange', {
            name: this.typeName,
            val: _type
        })
    }

    valueChange() {
        this.$emit('valueChange', {
            name: this.valueName,
            val: this.localValue
        })
    }

    onFocus() {
        this.focus = true
    }

    onBlur() {
        this.focus = false
    }
}
