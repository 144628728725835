


























import { Component } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'
import API from '@/api/index.js'
import Util from '@/utils/index.js'
import InputBox from '../../common/input-box/inputBox.vue'

@Component({
    components: {
        InputBox
    }
})
export default class BindPhone extends myMixins {
    phone: any = ''
    canClick = false
    action = 2 // 验证码类型 1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机
    showDialog = false

    valueChange(params: Param) {
        const { name, val } = params
        this[name] = val
        this.canClick = Util.checkPhone(this.phone)
        // 记录用户输入的手机号
        if (this.canClick) {
            this.$store.commit('login/updateInputPhone', this.phone)
        }
    }

    async checkPhoneAccount() {
        if (this.canClick) {
            const res = await API.checkPhoneStatus({ phone: this.phone })
            if (res && res.data && res.data.errorCode === 200) {
                const { status } = res.data.data
                if (status === 1) {
                    // 弹窗提示手机号已绑定
                    this.showDialog = true
                } else if (status === 2) {
                    // 走登录 在登录时绑定微信tokenID
                    this.$store.commit('login/updateCanPhoneBindWechat', true)
                    this.action = 2
                    this.sendYzm()
                } else if (status === 3) {
                    //  未注册 走注册流程
                    this.action = 1
                    this.sendYzm()
                }
            }
        }
    }

    // 注册送验证码
    async sendYzm() {
        this.$store.commit('login/updateTimeNumber', -1)
        this.getYZM()
        const res = await API.sendForYzm({
            mobile: this.phone,
            action: this.action
        })
        if (res && res.data && res.data.errorCode === 200) {
            this.$store.commit('login/updateTimePhone', this.phone)
            this.$store.commit('login/updateTimeAction', this.action)
            this.$nextTick(() => {
                this.routerChange('verificationCode')
            })
        } else if (res.data.errorMsg && res.data.errorMsg.includes('发送上限')) {
            this.routerChange('passwordLogin')
        }
    }

    // 使用当前账号登录 不绑定当前微信tokenID
    confirm() {
        this.action = 2
        this.$store.commit('login/updateCanPhoneBindWechat', false)
        this.sendYzm()
    }

    cancel() {
        this.phone = ''
    }
}
